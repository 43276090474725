import Post from "../post/Post";
import "./posts.css";

export default function Posts() {
  return (
    <div className="posts">
      <Post img="https://scontent.fphx1-1.fna.fbcdn.net/v/t39.30808-6/228801933_230311568961971_2309251909218239479_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=730e14&_nc_ohc=PpApEvbLdFQAX8BCote&_nc_ht=scontent.fphx1-1.fna&oh=7efdd2f2e497bf46792ca9b6fe10bbf9&oe=619CC222" />
    </div>
  );
}
