import "./header.css";

export default function Header() {
  return (
    <div className="header">
      <div className="headerTitles">
        <span className="headerTitleSm">CONSCIENCE - ESSENCE - SCIENCE - COMPETENCE - EXISTENCE</span>
        <span className="headerTitleLg">CONGOLAIS CONSCIENT</span>
        {/* <span className="headerTitleSm">CESCE - LA CONSCIENCE EST ESSENTIELLE POUR UNE SCIENCE D' ESSENCE POUR LA COMPETENCE D' EXISTENCE</span>
         */}
      </div>
      <img
        className="headerImg"
        src="https://scontent.fphx1-1.fna.fbcdn.net/v/t39.30808-6/228801933_230311568961971_2309251909218239479_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=730e14&_nc_ohc=PpApEvbLdFQAX8BCote&_nc_ht=scontent.fphx1-1.fna&oh=7efdd2f2e497bf46792ca9b6fe10bbf9&oe=619CC222"
        alt=""
      />
    </div>
  );
}
