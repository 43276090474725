import "./settings.css";
import Sidebar from "../../components/sidebar/Sidebar";

export default function Settings() {
  return (
    <div className="settings">
      <div className="settingsWrapper">
        <div className="settingsTitle">
          <span className="settingsTitleUpdate">Update Your Account</span>
          <span className="settingsTitleDelete">Delete Account</span>
        </div>
        <form className="settingsForm">
          <label>Profile Picture</label>
          <div className="settingsPP">
            <img
              src="https://scontent.fphx1-1.fna.fbcdn.net/v/t1.6435-9/162120278_152341993425596_6421296574704717627_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=a26aad&_nc_ohc=XrtL0BRmdeIAX-qkMMO&_nc_ht=scontent.fphx1-1.fna&oh=9dd549212f61daef5cb92599262b9cb3&oe=61BF148E"
              alt=""
            />
            <label htmlFor="fileInput">
              <i className="settingsPPIcon far fa-user-circle"></i>{" "}
            </label>
            <input
              id="fileInput"
              type="file"
              style={{ display: "none" }}
              className="settingsPPInput"
            />
          </div>
          <label>Username</label>
          <input type="text" placeholder="ConsciousCongolese" name="name" />
          <label>Email</label>
          <input type="email" placeholder="consciouscongolese@consciouscongolese.org" name="email" />
          <label>Password</label>
          <input type="password" placeholder="Password" name="password" />
          <button className="settingsSubmitButton" type="submit">
            Update
          </button>
        </form>
      </div>
      <Sidebar />
    </div>
  );
}
