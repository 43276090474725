import "./write.css";

export default function Write() {
  return (
    <div className="write">
      <img
        className="writeImg"
        src="https://scontent.fphx1-1.fna.fbcdn.net/v/t1.6435-9/162120278_152341993425596_6421296574704717627_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=a26aad&_nc_ohc=XrtL0BRmdeIAX-qkMMO&_nc_ht=scontent.fphx1-1.fna&oh=9dd549212f61daef5cb92599262b9cb3&oe=61BF148E"
        alt=""
      />
      <form className="writeForm">
        <div className="writeFormGroup">
          <label htmlFor="fileInput">
            <i className="writeIcon fas fa-plus"></i>
          </label>
          <input id="fileInput" type="file" style={{ display: "none" }} />
          <input
            className="writeInput"
            placeholder="Title"
            type="text"
            autoFocus={true}
          />
        </div>
        <div className="writeFormGroup">
          <textarea
            className="writeInput writeText"
            placeholder="Tell your story..."
            type="text"
            autoFocus={true}
          />
        </div>
        <button className="writeSubmit" type="submit">
          Publish
        </button>
      </form>
    </div>
  );
}
